<template>
  <div class="container mobileViewRadioButton">
    <div class="brokergaeContainer">
      <div class="left-corner">
        <p class="boldBigFont">Brokerage Details</p>
        <p
          style="
            color: blue;
            cursor: pointer;
            margin-left: 4px;
            font-size: medium;
          "
          @click="openViewDetailsPopup()"
        >
          View Details
        </p>
      </div>
    </div>
    <v-radio-group v-model="value" class="radio-group-container">
      <v-radio
        color="primary"
        :value="ASSISTED_HYBRID_PLAN"
        @click="chooseBrokeragePlan(ASSISTED_HYBRID_PLAN)"
      >
        <template v-slot:label>
          <div class="labelWithImage">
            <p class="label">Value Add Plan</p>
            <img :src="recommendedImage" width="100" />
          </div>
        </template>
      </v-radio>
      <v-radio
        color="primary"
        :value="ITRADE_PRIME_PLAN"
        @click="chooseBrokeragePlan(ITRADE_PRIME_PLAN)"
      >
        <template v-slot:label>
          <div class="labelContainer__manualDetails">
            <p class="label">iTrade Premier</p>
          </div>
        </template>
      </v-radio>
    </v-radio-group>
    <div style="display: flex">
      <img :src="compareIcon" width="20" />

      <p
        style="color: blue; cursor: pointer; margin-left: 4px"
        @click="openCompareBrokeragePopup()"
      >
        Compare
      </p>
    </div>
  </div>

  <v-bottom-sheet
    max-width="auto"
    class="d-block d-md-none bottom-sheet"
    v-model="bottomSheetOpen"
    :class="[{ 'display-sheet': bottomSheetOpen }]"
    v-if="isMobile"
  >
    <v-overlay v-model="bottomSheetOpen" @click="resetStates(true)"></v-overlay>
    <div class="bottomSheetContainer">
      <table>
        <thead style="border: 1px solid #ddd">
          <tr>
            <th style="text-align: left; width: 40%">Plan Name</th>
            <th
              v-for="plan in plans"
              :key="plan.planName"
              style="text-align: left; width: 30%; font-weight: 400"
            >
              {{ plan.planName }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, key) in details" :key="key">
            <td>{{ key }}</td>
            <td v-for="plan in plans" :key="plan.planName">
              {{ plan.details[key] || "" }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </v-bottom-sheet>

  <SlottedPopup
    :id="widgetId + 'brokerageDetails'"
    :params="{
      hasHeader: true,
      hasMaxWidth: true,
      persistent: transactionStatus === 'success',
      title: 'Brokerage Details',
      maxWidth: '850',
    }"
    :visible="showPopup"
    @onClose="resetStates(true)"
  >
    <div class="popupContainer">
      <div class="data-wrap">
        <table>
          <thead style="border: 1px solid #ddd">
            <tr>
              <td style="text-align: left; width: 40%">Plan Name</td>
              <th
                v-for="plan in plans"
                :key="plan.planName"
                style="text-align: left; width: 30%; font-weight: 400"
              >
                {{ plan.planName }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, key) in details" :key="key">
              <td>{{ key }}</td>
              <td v-for="plan in plans" :key="plan.planName">
                {{ plan.details[key] || "" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </SlottedPopup>

  <v-bottom-sheet
    class="d-block d-md-none bottom-sheet"
    v-model="conpareBottomSheet"
    :class="[{ 'display-sheet': conpareBottomSheet }]"
    v-if="isMobile"
  >
    <v-overlay
      v-model="conpareBottomSheet"
      @click="resetStates(true)"
    ></v-overlay>
    <div class="bottomSheetContainer">
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: end;
          width: 100%;
        "
      >
        <img :src="recommendedImage" width="100" height="40" />
      </div>
      <div class="table-container">
        <table>
          <thead>
            <tr>
              <th style="text-align: left">Benefits</th>

              <th
                v-for="plan in comparePlans"
                :key="plan.name"
                :class="[
                  plan?.name != 'iTrade Premier'
                    ? 'purpleBackground purpleBorder'
                    : '',
                ]"
                style="font-weight: 600"
              >
                <div class="plan-header">
                  {{ plan.name }}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(benefit, index) in benefits" :key="index">
              <td style="border: 2px solid #ddd; font-weight: 600">
                {{ benefit.text }}
              </td>
              <td
                v-for="plan in comparePlans"
                :key="plan.name"
                style="text-align: center"
                :class="[
                  plan?.name == 'iTrade Premier'
                    ? ' '
                    : index + 1 == benefits.length &&
                      plan?.name != 'iTrade Premier'
                    ? 'purpleBorder  purpleBorderBottom '
                    : 'purpleBorderBottom purpleBorder',
                ]"
              >
                <img
                  :src="failesCrossFilled"
                  v-if="
                    plan.data[index].color === 'red' &&
                    plan.data[index].value == 'No'
                  "
                  width="25"
                  height="25"
                />
                <img
                  :src="checkCircleFilled"
                  v-else-if="
                    plan.data[index].color === 'green' &&
                    plan.data[index].value == 'Yes'
                  "
                  width="25"
                  height="25"
                />
                <span
                  v-else-if="
                    plan.data[index].color === 'green' &&
                    plan.data[index].value != 'Yes'
                  "
                  style="color: forestgreen"
                  >{{ plan.data[index].value }}</span
                >
                <span v-else>{{ plan.data[index].value }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="buttonContainer" v-if="value === 'iTrade Premier'">
          <v-btn
            id="primaryButton"
            flat
            class="ml-3 buttonStyleClass"
            color="primary"
            size="medium"
            variant="flat"
            :ripple="false"
            type="button"
            v-on:click="setDefaultPlan(true)"
          >
            SWITCH TO VALUE ADD PLAN
          </v-btn>
        </div>
      </div>
    </div>
  </v-bottom-sheet>

  <SlottedPopup
    :id="widgetId + 'brokerageCompare'"
    :params="{
      hasHeader: true,
      hasMaxWidth: true,
      persistent: transactionStatus === 'success',
      title: 'Compare Plans',
      maxWidth: '800',
    }"
    :visible="showComparePopup"
    @onClose="resetCompareStates(true)"
  >
    <div class="popupContainer">
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: end;
          width: 100%;
        "
      >
        <img :src="recommendedImage" width="200" height="40" />
      </div>
      <div class="table-container">
        <table>
          <thead>
            <tr>
              <th style="text-align: left">Benefits</th>

              <th
                v-for="plan in comparePlans"
                :key="plan.name"
                :class="[
                  plan?.name != 'iTrade Premier'
                    ? 'purpleBackground purpleBorder'
                    : '',
                ]"
                style="font-weight: 600"
              >
                <div class="plan-header">
                  {{ plan.name }}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(benefit, index) in benefits" :key="index">
              <td style="border: 2px solid #ddd; font-weight: 600">
                {{ benefit.text }}
              </td>
              <td
                v-for="plan in comparePlans"
                :key="plan.name"
                style="text-align: center"
                :class="[
                  plan?.name == 'iTrade Premier'
                    ? ' '
                    : index + 1 == benefits.length &&
                      plan?.name != 'iTrade Premier'
                    ? 'purpleBorder  purpleBorderBottom '
                    : 'purpleBorderBottom purpleBorder',
                ]"
              >
                <img
                  :src="failesCrossFilled"
                  v-if="
                    plan.data[index].color === 'red' &&
                    plan.data[index].value == 'No'
                  "
                  width="25"
                  height="25"
                />
                <img
                  :src="checkCircleFilled"
                  v-else-if="
                    plan.data[index].color === 'green' &&
                    plan.data[index].value == 'Yes'
                  "
                  width="25"
                  height="25"
                />
                <span
                  v-else-if="
                    plan.data[index].color === 'green' &&
                    plan.data[index].value != 'Yes'
                  "
                  style="color: forestgreen"
                  >{{ plan.data[index].value }}</span
                >
                <span v-else>{{ plan.data[index].value }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="buttonContainer" v-if="value === 'iTrade Premier'">
          <v-btn
            id="primaryButton"
            flat
            class="ml-3 buttonStyleClass"
            color="primary"
            size="medium"
            variant="flat"
            :ripple="false"
            type="button"
            v-on:click="setDefaultPlan(true)"
          >
            SWITCH TO VALUE ADD PLAN
          </v-btn>
        </div>
      </div>
    </div>
  </SlottedPopup>
</template>
<script setup>
import useFormValue from "@/composables/useFormValue";
import { ref, reactive, onMounted } from "vue";
import { getImage } from "@/utils/utils";

import useMobileTabletDetector from "@/composables/useMobileDetector";
import { useStore } from "vuex";
import SlottedPopup from "./SlottedPopup.vue";
import ActionHandler from "@/handlers/action";
const props = defineProps({
  params: Object,
  id: String,
  layoutData: Object,
});
const widgetId = reactive(props.id);
let isMobile = useMobileTabletDetector();
const ITRADE_PRIME_PLAN = "iTrade Premier";
const ASSISTED_HYBRID_PLAN = "Value Add Plan";

const recommendedImage = getImage("RecomendedFull.svg");

let details = {};
let benefits;
let plans;
let comparePlans;

const store = useStore();

let transactionStatus = ref();
let bottomSheetOpen = ref(false);
let conpareBottomSheet = ref(false);
let showPopup = ref(false);
let showComparePopup = ref(false);
const checkCircleFilled = getImage("darkGreenCricleChecked.svg");
const failesCrossFilled = getImage("darkRedCircleChecked.svg");
const compareIcon = getImage("compareExchange.svg");

const { value } = useFormValue(widgetId);

onMounted(() => {
  setTimeout(() => {
    setDefaultPlan(false);
  }, 800);
});

async function openViewDetailsPopup() {
  // let plansToCompare =
  //   store.state?.apiResponses?.brokerageDataPlan?.planName === "value_add_plan"
  //     ? "value_add_plan"
  //     : "value_add_plan," +
  //       store.state?.apiResponses?.brokerageDataPlan?.planName;
  let plansToCompare =
    store.state?.apiResponses?.brokerageDataPlan?.planName &&
    "value_add_plan," + store.state?.apiResponses?.brokerageDataPlan?.planName;
  let apiUrl = "/v1/kyc/brokerage/details?plannames=" + plansToCompare;

  await ActionHandler.handleSingleAction({
    api: apiUrl,
    method: "GET",
    type: "callAPIOnLoad",
    requestOn: "onChangeVal",
    widgetId: "BrokerageDetailsPopup",
  });

  plans = store.state.apiResponses.BrokerageDetailsPopup;
  store.state.apiResponses.BrokerageDetailsPopup.forEach((plan) => {
    Object.keys(plan.details).forEach((key) => {
      if (!details[key]) {
        details[key] = {};
      }
      details[key][plan.planName] = plan.details[key];
    });
  });
  console.log("details ", details);
  if (isMobile.value) {
    bottomSheetOpen.value = true;
  } else {
    showPopup.value = true;
  }
}

const openCompareBrokeragePopup = async () => {
  let action = {
    api: "/v1/brokerage/hybridplan/comparison",
    method: "GET",
  };

  await ActionHandler.fetchAPI(action, {}, {}, "BrokerageComparePopup", false);

  const apiResp = store.state.apiResponses;

  comparePlans = apiResp.BrokerageComparePopup.planDetail;
  benefits = apiResp.BrokerageComparePopup.planDetail[0].data;
  if (isMobile.value) {
    conpareBottomSheet.value = true;
  } else {
    showComparePopup.value = true;
  }

  let AnalyticEventOnClick = {
    cs: {
      bodyParameters: {
        event_screen: "s-employmentype",
        event_type: "click",
        event_sub_type: "button",
        event_name: "compareplan",
        event_id: "73.0.0.26.11",
      },
    },
    method: "POST",
    type: "callAnalyitcs",
  };

  ActionHandler.handleSingleAction(AnalyticEventOnClick, widgetId, {}, "");
};

const setDefaultPlan = (onButtonClick) => {
  if (store?.state?.apiResponses?.brokerageDataPlan?.needToShow) {
    if (store?.state?.formData?.planName) {
      value.value = store?.state?.formData?.planName;

      store.dispatch("setFormData", {
        key: "planName",
        value: store?.state?.formData?.planName,
      });
    } else {
      value.value = ASSISTED_HYBRID_PLAN;
      store.dispatch("setFormData", {
        key: "planName",
        value: ASSISTED_HYBRID_PLAN,
      });
    }

    if (onButtonClick) {
      value.value = ASSISTED_HYBRID_PLAN;
      store.dispatch("setFormData", {
        key: "planName",
        value: ASSISTED_HYBRID_PLAN,
      });
      resetCompareStates(true);
      let AnalyticEventOnClick = {
        cs: {
          bodyParameters: {
            event_screen: "bs-employmentype",
            event_type: "click",
            event_sub_type: "button",
            event_name: "compareplan",
            event_id: "73.0.0.26.12",
          },
          event_metadata: {
            close: "switch_to_recommended_plan",
          },
        },
        method: "POST",
        type: "callAnalyitcs",
      };

      ActionHandler.handleSingleAction(AnalyticEventOnClick, widgetId, {}, "");
    }
  }
};

const chooseBrokeragePlan = (brokeragePlan) => {
  store.dispatch("setFormData", {
    key: "planName",
    value: brokeragePlan,
  });
};

const resetStates = () => {
  if (isMobile.value) {
    bottomSheetOpen.value = false;
  } else {
    showPopup.value = false;
  }
};

const resetCompareStates = () => {
  if (isMobile.value) {
    conpareBottomSheet.value = false;
  } else {
    showComparePopup.value = false;
  }
};
</script>

<style scoped lang="scss">
.container {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (max-width: 500px) {
    gap: 1rem;
    margin-top: 0rem;
    height: 100%;
  }
}

.labelContainer {
  display: flex;
  width: fit-content;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
}
.labelContainer__manualDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  gap: 0.5rem;
  text-transform: capitalize;
}
.labelContainer__phonePe {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

:deep {
  .v-selection-control {
    align-items: flex-start;
    height: 72px;
    border: 1px solid #d1d4d6;
    border-radius: 8px;
    align-items: center;
    width: 50%;
    @media (max-width: 500px) {
      height: 48px;
      width: 100%;
    }
  }
  .v-selection-control--dirty {
    background-color: #e8ebfa;

    .label {
      color: #3f5bd9 !important;
      font-weight: 600;
      min-width: fit-content;
    }
  }

  .v-selection-control-group {
    flex-direction: row;
    gap: 10px;
    @media (max-width: 500px) {
      flex-direction: column;
    }
  }

  .mdi-check-circle {
    color: #52de8c;
  }

  .v-radio {
    padding: 14px 4px 14px 0px;
    @media (max-width: 400px) {
      padding: 2px;
    }
  }
  .v-input__details {
    height: 10px;
  }
}

.appImg {
  width: auto;
  min-width: fit-content;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.display-sheet {
  height: 75vh;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  transition: height ease-in 1000ms;
}

.bottomSheetContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  // gap: 20px;
}

.popupStatus {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 20px;
}

.textBoldBlue {
  color: #3f5bd9;
  font-weight: 600;
}

.popupContainer {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  gap: 5px;
}

.qrTimer {
  padding: 10px;
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}

.close {
  position: absolute;
  top: 0px;
  right: 0px;
  left: auto;
}

.labelWithImage {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.label {
  min-width: fit-content;
  @media (max-width: 1024px) {
    font-size: 0.8rem;
  }
}

:deep {
  .v-label {
    opacity: 1;
  }
  .radio-group-container {
    width: 100%;
    flex-direction: row;
  }
  .buttonClass {
    width: 382px;
    margin: auto;
    @media (max-width: 400px) {
      width: 90% !important;
    }
  }
}

.brokergaeContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 5px;
}
.left-corner {
  text-align: left;
  display: flex;
}
.right-corner {
  text-align: right;
}

.table-container {
  overflow-x: auto;
  margin-top: 5px;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
}

th,
td {
  // border: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  // border-radius: 3px;
  padding: 10px 10px;
  text-align: left;
  vertical-align: middle;
  border-radius: 3px;
}

th {
  text-align: center;
  border: 2px solid #ddd;
  border-radius: 5px;
  font-size: medium;
  font-weight: 100;
}

span {
  display: inline-block;
  width: 100%;
}

.plan-header {
  position: relative;
}

.purpleBackground {
  background-color: #716acb;
  color: #f2f2f2;
}

.purpleBorder {
  border-left: 1px solid #716acb;
  border-right: 1px solid #716acb;
  border-radius: 5px;
}

.purpleBorderBottom {
  border-bottom: 1px solid #716acb;
  border-radius: 5px;
}

.boldBigFont {
  font-size: x-large;
  font-weight: 600;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonStyleClass {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 1rem;
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.mobileViewRadioButton {
  @media (max-width: 400px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
</style>
