import { API_ACTION_TYPES } from "@/handlers/action";
import store from "@/store/store";
import { getCookieValue } from "@/services/HttpClient";

export default function useGetInitialRoute() {
  const {
    state: { action, persistedNavStack },
  } = store;
  let currentUrl = window.location;
  let initialRoute = "";
  if (currentUrl.toString().includes("#/jointAccountLogin")) {
    initialRoute = "jointAccountLogin";
    // const changedUrl = currentUrl.toString().replace("#/", "");
    // window.open(changedUrl);
  } else if (currentUrl.toString().includes("#/login")) {
    initialRoute = "login";
    // const changedUrl = currentUrl.toString().replace("#/", "");
    // window.open(changedUrl);
  } else if (currentUrl.toString().includes("termsAndConditionPg")) {
    initialRoute = "termsAndConditionPg";
  } else if (
    currentUrl.toString().includes("creditBureauTermsAndConditionPg")
  ) {
    initialRoute = "creditBureauTermsAndConditionPg";
  } else if (sessionStorage.getItem("journey-type") === "jointAccount") {
    const token = getCookieValue(import.meta.env.VITE_APP_GUEST_COOKIE);
    if (!token) {
      initialRoute = "jointAccountLogin";
    } else {
      initialRoute =
        action.type === API_ACTION_TYPES.navigate
          ? action?.value?.name
          : persistedNavStack[persistedNavStack.length - 1];
    }
  } else {
    const token = getCookieValue(import.meta.env.VITE_APP_GUEST_COOKIE);
    if (!token) {
      initialRoute = "login";
    } else {
      initialRoute =
        action.type === API_ACTION_TYPES.navigate
          ? action?.value?.name
          : persistedNavStack[persistedNavStack.length - 1];
    }
  }
  return { initialRoute };
}
