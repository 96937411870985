<template>
  <div class="popupContainer">
    <p
      style="
        text-align: left;
        font-size: 14px;
        background-color: #f4f6fb;
        padding: 4px 8px 4px 8px;
      "
    >
      We have revised our brokerage plans and pricing. Please review the details
      before completing the account opening process.
    </p>
    <div class="tableContainer">
      <table>
        <tr>
          <th></th>
        </tr>
        <tr class="tableRow">
          <td class="tableColumn">Details</td>
          <td class="tableColumn text-grey">{{ respFromAPI[0].planName }}</td>
        </tr>
        <template v-for="(item, idx) in rowsName" :key="idx">
          <tr class="tableRow">
            <td class="tableColumn">
              {{ item }}
            </td>
            <td class="tableColumn text-grey">
              {{ respFromAPI[0].details?.[item] }}
            </td>
          </tr>
        </template>
      </table>
    </div>
  </div>
  <p class="consentPara">
    By proceeding, you agree that you have read and verified the updated
    brokerage plan.
  </p>
  <div style="margin: auto; width: max-content; padding: 8px">
    <FormButton :params="formButtonParams" id="proceedToEsignFormButton" />
  </div>
</template>

<script setup>
import { ref } from "vue";
import FormButton from "./FormButton.vue";

const props = defineProps({
  id: String,
  respFromAPI: Object,
  rowsName: Array,
});

const respFromAPI = ref(props.respFromAPI);
const rowsName = ref(props.rowsName);

const formButtonParams = {
  btnText: "Proceed to e-sign",
  btnColor: "primary",
  btnSize: "large",
  customStyles: "margin:auto !important;",
  hasLoadingIndicator: true,
  hasFullMobWidth: true,
  onSubmit: [
    {
      api: "/v1/kyc/setu/esign",
      triggerWhen: "features.setuEsign:boolean|=|true",
      method: "GET",
      type: "navigateWithAPI",
      requestOn: "onChangeVal",
      ct: {
        event_name: "KYC_Screen_Load",
        bodyParameters: {
          event_category: "KYC2.0",
          eventAction: "esign_Setu",
          event_type: "Proceed to e-sign",
          AUC: "STATE:appNumber",
        },
      },
    },
    {
      cs: {
        bodyParameters: {
          event_screen: "bs-esign",
          event_type: "click",
          event_sub_type: "button",
          event_name: "proceed_to_esign_new",
          event_id: "73.0.0.42.11",
        },
      },
      method: "POST",
      type: "callAnalyitcs",
    },
  ],
};
</script>

<style scoped>
.popupContainer {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 3px;
  padding: 6px 10px 6px 10px;
  flex: 1;
  overflow-y: auto;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 14px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

.tableColumn {
  text-align: left;
  padding: 8px;
  vertical-align: top;
  font-weight: 600;
  color: #425061;
}

.tableRow {
  text-align: center;
}

.consentPara {
  text-align: center;
  color: slategrey;
  font-weight: bolder;
  font-size: 14px;
}

.text-grey {
  color: slategrey !important;
}
</style>
