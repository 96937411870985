<template>
  <v-btn
    color="primary"
    size="large"
    style="margin: auto"
    :ripple="false"
    @click="openPopup"
    :loading="planFetchLoader"
    :disabled="isButtonDisable"
  >
    Submit Review
  </v-btn>

  <SlottedPopup
    :id="widgetId + 'brokerageCompare'"
    :params="{
      hasHeader: true,
      hasMaxWidth: true,
      title: 'Updated Brokerage Plan',
      maxWidth: '800',
    }"
    :visible="openDeliveryChargePopup && !isMobile"
    @onClose="openPopup"
    v-if="respFromAPI?.length && rowsName?.length"
  >
    <OldAndNewPlanJointAccountVue
      v-bind:respFromAPI="respFromAPI"
      v-bind:rowsName="rowsName"
    />
  </SlottedPopup>

  <v-bottom-sheet
    class="d-block d-md-none bottom-sheet planBottomSheet"
    v-model="openDeliveryChargePopup"
    :class="[{ 'display-sheet': openDeliveryChargePopup }]"
    v-if="isMobile && respFromAPI?.length && rowsName?.length"
  >
    <v-overlay v-model="openDeliveryChargePopup"></v-overlay>
    <p
      style="
        font-size: 18px;
        font-weight: bolder;
        text-align: left;
        padding: 6px 10px 6px 10px;
      "
    >
      We've a better Plan for you
    </p>
    <OldAndNewPlanJointAccountVue
      :respFromAPI="respFromAPI"
      :rowsName="rowsName"
    />
  </v-bottom-sheet>
</template>

<script setup>
import SlottedPopup from "./SlottedPopup.vue";
import OldAndNewPlanJointAccountVue from "./OldAndNewPlanJointAccount.vue";
import { ref } from "vue";
import useMobileTabletDetector from "@/composables/useMobileDetector";
import { http } from "@/services/HttpClient";
import { clickStream } from "@/composables/clickStreamAnalytics";
import useRuleParser from "@/composables/useRuleParser";

const props = defineProps({
  params: Object,
  id: String,
  layoutData: Object,
});

const widgetId = ref(props.id);

let isMobile = useMobileTabletDetector();

const openDeliveryChargePopup = ref(false);

const rowsName = ref([]);

const respFromAPI = ref([]);

const planFetchLoader = ref(false);

const isButtonDisable = useRuleParser(
  "formData.jointAccountHolderNames:arrayLength|=|1"
);

const openPopup = async () => {
  openDeliveryChargePopup.value = !openDeliveryChargePopup.value;

  if (
    openDeliveryChargePopup.value &&
    !respFromAPI?.value?.length &&
    !rowsName?.value?.length
  ) {
    let deliveryChargeBsImpressionAnalytics = {
      event_screen: "bs-esign",
      event_type: "impression",
      event_sub_type: "screen",
      event_name: "BrokerageNudge",
      event_id: "73.0.0.42.10",
    };
    clickStream.addEventList(deliveryChargeBsImpressionAnalytics);

    planFetchLoader.value = true;

    const endpoint = "/v1/kyc/brokerage/details?plannames=iTrade Premier";
    const res = await http.get(endpoint);

    if (res?.data?.length) {
      respFromAPI.value = JSON.parse(JSON.stringify(res.data));

      rowsName.value = Object.keys(respFromAPI.value?.[0]?.details || {});
    }
    planFetchLoader.value = false;

    isButtonDisable.value =
      planFetchLoader.value &&
      useRuleParser("formData.jointAccountHolderNames:arrayLength|=|1");
  }
};
</script>

<style scoped>
.planBottomSheet {
  display: flex !important;
  flex-direction: column !important;
}
</style>
