<template>
  <div class="ratingBox" v-if="showRating">
    <h4 style="margin: auto">How was your onboarding experience?</h4>
    <div class="ratingContent">
      <span>Not happy</span>
      <span>Very happy</span>
    </div>
    <div class="ratingMeterDiv">
      <template v-for="(item, i) in items" :key="i">
        <span
          class="counter"
          :class="[{ 'active-rating': isActive === i }]"
          v-on:click="submitRating(i)"
        >
          <span v-on:click="submitRating(i)">{{ i }}</span>
        </span>
        <v-img
          v-if="isActive === i"
          :class="[{ 'active-icon': isActive === i }]"
          :src="getImage(emoticon)"
          width="48"
          height="48"
        ></v-img>
      </template>
    </div>
    <div
      class="suggestionContainer"
      v-if="Object.keys(suggestion || {})?.length"
    >
      <p>{{ suggestion?.title }}</p>
      <template v-for="(item, i) in suggestion?.suggestionList || []" :key="i">
        <div
          class="suggestionOption"
          @click="handleSuggestionClick(item, i)"
          :class="[
            {
              activeSuggestion: selectedIdx?.length && selectedIdx.includes(i),
            },
          ]"
        >
          {{ item?.name }}
        </div>
      </template>
      <v-text-field
        placeholder="Leave a review  (Optional)"
        variant="plain"
        style="background-color: white; transition: 200ms linear; padding: 5px"
        :value="otherReasonsText"
        id="otherSuggestionsText"
        v-model="otherReasonsText"
      />
      <v-btn
        color="primary"
        size="large"
        style="margin: auto; font-weight: bold"
        :ripple="false"
        @click="submitSuggestions"
        :disabled="submitBtnDisable || submitBtnLoader"
        :loading="submitBtnLoader"
      >
        Submit
      </v-btn>
    </div>
  </div>
  <div v-else class="w-100">
    <h4 class="text-center w-100 gray-500" v-if="platform === 'IOS'">
      Click on the 'Done' button on Top left to proceed
    </h4>
    <h4 class="text-center w-100 gray-500" v-else>
      <v-btn
        id="primaryButton"
        color="primary"
        size="large"
        style="margin: auto; font-weight: bold"
        :ripple="false"
        @click="redirectBackToApp"
      >
        EXPLORE APP
      </v-btn>

      <p v-if="showFallbackText">
        In case of no redirection, open Angel One App manually.
      </p>
    </h4>
  </div>
</template>

<script>
import { reactive, ref, computed } from "@vue/reactivity";
import ActionHandler from "@/handlers/action";
import { useStore } from "vuex";
import { checkPlatform, getImage } from "@/utils/utils";
import CleaverTap from "@/composables/Clevertap";
import LocalNavigator from "@/composables/useNavigation";
import { clickStream } from "@/composables/clickStreamAnalytics";
import { onBeforeMount, watchEffect } from "vue";
import {
  APP_HOME_SCREEN_DEEPLINK,
  IN_SAME_SESSION,
  REDIRECT_FROM_TWA,
} from "@/constants";
import { MOBILE_WIDTH_THRESHOLD } from "@/constants/bandRPDConstants";

export default {
  name: "RatingWidget",
  props: {
    id: String,
    params: Object,
  },
  setup(props) {
    const {
      items = 11,
      method,
      type,
      api,
      requestOn,
      ct,
      suggestionConfig,
    } = reactive(props.params);

    const widgetId = reactive(props.id);
    const store = useStore();
    const isActive = ref(Number);
    let emoticon = ref("");
    let suggestion = ref(null);
    let selectedSuggestions = ref({
      reasonsSelected: [],
      selectedValues: [],
    });
    let selectedIdx = ref([]);
    let otherReasonsText = ref("");
    let submitBtnLoader = ref(false);

    let showRating = ref(false);
    let appRedirectTimer = ref(3);
    let intervalId = ref();

    let platform = ref("");

    let windowWidth = ref();

    let showFallbackText = ref(false);

    onBeforeMount(() => {
      platform.value = checkPlatform();

      windowWidth.value =
        window?.innerWidth ||
        document?.documentElement?.clientWidth ||
        document?.body?.clientWidth;

      if (windowWidth.value > MOBILE_WIDTH_THRESHOLD) {
        showRating.value = true;
        return;
      }

      const inSameSession = sessionStorage.getItem(IN_SAME_SESSION) === "true";

      const isRedirectFromTWA =
        sessionStorage.getItem(REDIRECT_FROM_TWA) === "true";

      if (isRedirectFromTWA && inSameSession) {
        showRating.value = false;
      } else {
        showRating.value = true;
      }
    });

    const redirectBackToApp = () => {
      showFallbackText.value = true;
      window.open(APP_HOME_SCREEN_DEEPLINK, "_blank");
    };

    // const clickStream = new ClickStreamAnalytics();

    const SUGGESTION_CARD_CS_EVENT = {
      event_screen: "bs-NpsFeedback",
      event_name: "bs-NpsFeedback",
      event_id: "73.0.0.32.8",
      event_type: "impression",
      event_sub_type: "card",
      event_metadata: {},
    };

    const SUBMIT_BTN_CLICK_EVENT = {
      event_screen: "bs-NpsFeedback",
      event_name: "submit",
      event_id: "73.0.0.32.9",
      event_type: "click",
      event_sub_type: "button",
    };

    const submitBtnDisable = computed(() => {
      if (
        otherReasonsText.value ||
        selectedSuggestions.value.selectedValues?.length
      ) {
        return false;
      }
      return true;
    });

    const handleSuggestionClick = (newSuggestion, idx) => {
      if (
        !selectedSuggestions.value.selectedValues?.includes(newSuggestion.value)
      ) {
        selectedSuggestions.value.selectedValues = [
          ...selectedSuggestions.value.selectedValues,
          newSuggestion.value,
        ];
      } else {
        selectedSuggestions.value.selectedValues =
          selectedSuggestions.value.selectedValues?.filter(
            (item) => item !== newSuggestion.value
          );
      }

      if (!selectedIdx.value?.includes(idx)) {
        selectedIdx.value = [...selectedIdx.value, idx];
      } else {
        selectedIdx.value = selectedIdx.value?.filter((item) => item !== idx);
      }

      selectedSuggestions.value.reasonsSelected = [
        ...new Set([
          ...selectedSuggestions.value.reasonsSelected,
          newSuggestion.name,
        ]),
      ];
    };

    const submitSuggestions = async () => {
      let body = {
        suggestedIds: JSON.stringify(
          selectedSuggestions.value.selectedValues || []
        ),
        comment: otherReasonsText.value || "",
      };

      const FEEDBACK_API = {
        api: "/v1/kyc/feedback",
        method,
      };

      try {
        submitBtnLoader.value = true;
        let resonsMetaData = {};

        if (selectedSuggestions?.value?.reasonsSelected?.length) {
          selectedSuggestions.value.reasonsSelected.forEach((item, idx) => {
            resonsMetaData[`reason${idx + 1}`] = item;
          });
        }
        const submitEvent = {
          ...SUBMIT_BTN_CLICK_EVENT,
          event_metadata: {
            ...resonsMetaData,
          },
        };
        clickStream.addEventList(submitEvent);
        await ActionHandler.fetchAPI(FEEDBACK_API, body, {}, widgetId, false);
        const apiResp = store.state.apiResponses[widgetId];
        const features = { ...store.state.features };

        if (apiResp?.data?.feedback != null) {
          store.dispatch("setFeatures", {
            ...features,
            feedback: apiResp.data.feedback,
          });
        }
        if (apiResp.status === "success") {
          CleaverTap.addEvents("KYC_CTA_Clicked", {
            event_type: "Rating API Click",
            event_category: "KYC2.0",
            event_action: "thanks",
            AUC: store.state.formData.appNumber,
            score: rating,
          });
        }
        if (apiResp.status === "success") {
          LocalNavigator.push("/feedback-submitted");
        }
        submitBtnLoader.value = false;
      } catch (err) {
        submitBtnLoader.value = false;
      }
    };

    const submitRating = async (rating) => {
      if (isActive.value !== rating) {
        selectedIdx.value = [];
        selectedSuggestions.value.selectedValues = [];
        selectedSuggestions.value.reasonsSelected = [];
      }
      isActive.value = rating;
      clickStream.addEventList(SUGGESTION_CARD_CS_EVENT);

      if (rating <= 6) {
        suggestion.value = { ...suggestionConfig[0] };
        emoticon.value = "sad-emoticon.svg";
      } else if (rating > 6 && rating <= 8) {
        suggestion.value = { ...suggestionConfig[1] };
        emoticon.value = "ok-emoticon.svg";
      } else if (rating >= 9) {
        suggestion.value = { ...suggestionConfig[2] };
        emoticon.value = "happy-emoticon.svg";
      }

      store.dispatch("setFormData", {
        key: widgetId,
        value: rating.toString(),
      });

      let requestAction = {
        bodyParameters: {
          score: widgetId,
        },
        method: method,
        type: type,
        api: api,
        requestOn: requestOn,
      };

      CleaverTap.addEvents("KYC_CTA_Clicked", {
        event_type: "Rating API Click",
        event_category: "KYC2.0",
        event_action: "thanks",
        AUC: store.state.formData.appNumber,
        score: rating,
      });

      const FEEDBACK_SCORE_API = {
        api: "/v1/kyc/feedback",
        method,
      };

      let body = {
        score: rating.toString(),
      };

      await ActionHandler.fetchAPI(
        FEEDBACK_SCORE_API,
        body,
        {},
        widgetId,
        false
      );
    };

    return {
      items,
      submitRating,
      method,
      type,
      api,
      requestOn,
      isActive,
      emoticon,
      getImage,
      suggestion,
      selectedSuggestions,
      handleSuggestionClick,
      submitSuggestions,
      selectedIdx,
      otherReasonsText,
      submitBtnDisable,
      submitBtnLoader,
      showRating,
      appRedirectTimer,
      platform,
      redirectBackToApp,
      showFallbackText,
    };
  },
};
</script>

<style scoped lang="scss">
.ratingBox {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  max-width: 450px;
  min-height: 160px;
  margin: 1rem auto;
  background: #f4f6fb;
  border-radius: $border-radius;
  position: relative;

  .ratingMeterDiv {
    display: flex;
    padding: 10px;
    background: #e8ebf1;
    border-radius: $border-radius;
    margin: 8px 0px;
  }

  .counter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 32px;
    font-size: $font-size-sm;
    background: $white;
    border-radius: $border-radius;
    margin: 0px 5px;
    border: 1px solid $danger;
    color: $danger;

    &.active-rating {
      background: #feebeb;
      margin-top: -5px;
      transition: all ease-in 333ms;
      position: relative;

      &:after {
        bottom: -16px;
        left: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(5, 7, 8, 0);
        border-bottom-color: $gray-800;
        border-width: 7px;
        margin-left: -7px;
      }
    }

    cursor: pointer;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &:nth-of-type(n + 8) {
      border-color: $warning;
      color: $warning;

      &.active-rating {
        background: #fff7e9;
      }
    }

    &:nth-of-type(n + 10) {
      border-color: $success;
      color: $success;

      &.active-rating {
        background: #e3f8f6;
      }
    }
  }

  .active-icon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -24px;
  }

  .ratingContent {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: $gray-800;
    font-size: $font-size-sm;
  }

  .suggestionContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .suggestionOption {
    background-color: white;
    width: 383px;
    padding: 10px;
    border: 1px solid #e8ebf1;
    border-radius: 4px;
    text-align: left;
    font-size: 14px;
  }

  .suggestionOption:hover {
    cursor: pointer;
  }

  .activeSuggestion {
    border: 1px solid #3f5bd9;
    transition: 200ms linear;
  }

  @media (max-width: 500px) {
    max-width: 340px;
    padding-left: 10px;
    padding-right: 10px;

    .ratingMeterDiv {
      padding: 10px 8px;
    }

    .counter {
      margin: 0 2px;
    }

    .suggestionOption {
      width: 300px;
    }
  }

  @media (max-width: 360px) {
    .counter {
      width: 20px;
    }
  }
}
</style>
