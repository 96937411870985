<template>
  <v-form ref="formRef" v-model="valid" lazy-validation @submit.prevent>
    <div v-for="row in layoutData" :key="row[0].id" class="column">
      <div
        class="row flex-md-row"
        :class="[{ 'flex-column': row[0]?.flexColumn }]"
      >
        <StyleContainer
          v-for="widget in row"
          :key="widget.id"
          :style="widget.style"
          :width="widget.width"
          v-show="useRuleParser(widget?.visibleWhen)"
          :class="haveAnyClass(widget.cssClass)"
        >
          <component
            :is="widget.component"
            :screenID="widget.id"
            :id="widget.id"
            :formRef="formRef"
            :params="widget.params"
            :layoutData="layoutData"
            :validations="widget.validations"
            :shouldDisableKey="shouldDisableStateKey"
            @click="handleAction(widget.onTap, widget.id, formRef, screenId)"
            @input="
              debHandleAction(widget.onInput, widget.id, formRef, screenId)
            "
          ></component>
        </StyleContainer>
      </div>
    </div>
  </v-form>
</template>

<script>
// Core
import { onMounted, onUpdated, ref } from "vue";

// Extra Libs
import _ from "underscore";

// import DatepickerWidgetVue from "@/widgets/DatepickerWidget.vue";

// Normal import for chunk size < 0.5 KiB widgets
import InfoWidgetVue from "@/widgets/InfoWidget.vue";
import CheckboxWidgetVue from "@/widgets/CheckboxWidget.vue";
import CarouselsWidgetVue from "@/widgets/CarouselsWidget.vue";
import GuidelineDetailsWidgetVue from "@/widgets/GuidelineDetailsWidget.vue";
import ImageWidgetVue from "@/widgets/ImageWidget.vue";
import GroupSelectDropdownWidgetVue from "@/widgets/GroupSelectWidget.vue";
import AutoCompleteWidgetVue from "@/widgets/AutoCompleteWidget.vue";
import CardCollectionWidgetVue from "@/widgets/CardCollectionWidget.vue";
import TextElementWValueWidgetVue from "@/widgets/TextElementWValueWidget.vue";
import NetworkImageWidgetVue from "@/widgets/NetworkImageWidget.vue";
import ResendWidgetVue from "@/widgets/ResendWidget.vue";
import FormButtonVue from "@/widgets/FormButton.vue";
import ImageCropperVue from "@/widgets/ImageCropper.vue";
import RadioButtonVue from "@/widgets/RadioButtonWidget.vue";
import ButtonWidgetVue from "@/widgets/ButtonWidget.vue";
import UploadFileWidgetVue from "@/widgets/UploadFileWidget.vue";
import OAuthWidgetVue from "@/widgets/OAuthWidget.vue";
import SignaturePadWidgetParentVue from "@/widgets/SignatureWidgetParent.vue";
import NewDatePickerVue from "@/widgets/NewDatePicker.vue";
import HyperVergeWidgetVue from "@/widgets/HyperVergeWidget.vue";
import DocumentListVue from "@/widgets/DocumentList.vue";
import StyleContainer from "./StyleContainer.vue";
import StepperWidgetVue from "@/widgets/StepperWidget.vue";
import HeadingWidgetVue from "@/widgets/HeadingWidget.vue";
import TextElementWidgetVue from "@/widgets/TextElementWidget.vue";
import DynamicTextElementWidgetVue from "@/widgets/DynamicTextElementWidget.vue";
import AlertWidgetVue from "@/widgets/AlertWidget.vue";
import AadharLinkAlertVue from "@/widgets/AadharLinkAlert.vue";
import TextFieldWidgetVue from "@/widgets/TextFieldWidget.vue";
import ListWidgetVue from "@/widgets/ListWidget.vue";
import SelectDropdownVue from "@/widgets/SelectDropdownWidget.vue";
import CardWidgetVue from "@/widgets/CardWidget.vue";
import RatingWidgetVue from "@/widgets/RatingWidget.vue";
import AvatarWidget from "@/widgets/AvatarWidget.vue";
import ComboBoxWidgetVue from "@/widgets/ComboBoxWidget.vue";
import SignatureTypeVue from "@/widgets/SignatureType.vue";
import PaymentWidgetVue from "@/widgets/PaymentWidget.vue";
import BrokeragePlanWidgetVue from "@/widgets/BrokeragePlanWidget.vue";
import FeedbackSubmittedVue from "@/widgets/FeedbackSubmitted.vue";
import ConfirmBankDetailsVue from "@/widgets/ConfirmBankDetails.vue";
import BrowserSettingsVue from "@/widgets/BrowserSettings.vue";
import PdfPreviewVue from "@/widgets/PdfPreview.vue";
import FundSettlementFrequencyVue from "@/widgets/FundSettlementFrequency.vue";
import CarouselsInfoWidgetVue from "@/widgets/CarouselsInfoWidget.vue";
import TopBankListWidgetVue from "@/widgets/TopBankListWidget.vue";
import BankSearchAutoCompleteVue from "@/widgets/BankSearchAutoComplete.vue";
import LeadBlockCardVue from "@/widgets/LeadBlockCard.vue";
import HorizontalLineWidgetVue from "@/widgets/HorizontalLineWidget.vue";
import DeliveryChargeJointAccountVue from "@/widgets/DeliveryChargeJointAccount.vue";
import OldAndNewPlanJointAccountVue from "@/widgets/OldAndNewPlanJointAccount.vue";
// Handlers
import ActionHandler from "@/handlers/action";
import useRuleParser from "@/composables/useRuleParser";
import useButtonDisabledHooks from "@/composables/useButtonDisabledHooks";
import JointEsignVue from "@/widgets/JointEsign.vue";
import JointAccountHolderVue from "@/widgets/JointAccountHolder.vue";

import BrokeragePlanVue from "@/widgets/BrokeragePlan.vue";
import CkycConfirmationVue from "@/widgets/CkycConfirmationWidget.vue";
import DeliveryChargeVue from "@/widgets/DeliveryCharge.vue";
import OldAndNewPlanVue from "@/widgets/OldAndNewPlan.vue";

export default {
  name: "DynamicLayout",
  props: {
    layout: Object,
    isTextFiledDisabled: String,
    id: String,
  },
  components: {
    StyleContainer,
    ButtonWidget: ButtonWidgetVue,
    Stepper: StepperWidgetVue,
    Image: ImageWidgetVue,
    Heading: HeadingWidgetVue,
    TextElement: TextElementWidgetVue,
    DynamicTextElement: DynamicTextElementWidgetVue,
    Upload: UploadFileWidgetVue,
    AlertWidget: AlertWidgetVue,
    RadioButton: RadioButtonVue,
    SelectDropdown: SelectDropdownVue,
    InfoWidget: InfoWidgetVue,
    AutoCompleteWidget: AutoCompleteWidgetVue,
    CheckBoxWidget: CheckboxWidgetVue,
    TextFieldWidget: TextFieldWidgetVue,
    ListWidget: ListWidgetVue,
    SignaturePadWidgetParent: SignaturePadWidgetParentVue,
    CardWidget: CardWidgetVue,
    CardCollectionWidget: CardCollectionWidgetVue,
    RatingWidget: RatingWidgetVue,
    // DatepickerWidget: DatepickerWidgetVue,
    CarouselsWidget: CarouselsWidgetVue,
    NetworkImage: NetworkImageWidgetVue,
    HyperVergeWidget: HyperVergeWidgetVue,
    DocumentList: DocumentListVue,
    GroupSelectDropdownWidget: GroupSelectDropdownWidgetVue,
    FormButton: FormButtonVue,
    NewDatePicker: NewDatePickerVue,
    ResendWidget: ResendWidgetVue,
    CropWidget: ImageCropperVue,
    GuidelineDetailsWidget: GuidelineDetailsWidgetVue,
    TextElementWValueWidget: TextElementWValueWidgetVue,
    OAuthWidget: OAuthWidgetVue,
    AadharLinkAlert: AadharLinkAlertVue,
    AvatarWidget: AvatarWidget,
    ComboBoxWidget: ComboBoxWidgetVue,
    SignatureTypeWidget: SignatureTypeVue,
    PaymentWidget: PaymentWidgetVue,
    BrokeragePlanWidget: BrokeragePlanWidgetVue,
    ConfirmBankDetails: ConfirmBankDetailsVue,
    BrowserSettings: BrowserSettingsVue,
    PdfPreview: PdfPreviewVue,
    FeedbackSubmitted: FeedbackSubmittedVue,
    FundSettlementFrequency: FundSettlementFrequencyVue,
    CarouselsInfoWidget: CarouselsInfoWidgetVue,
    TopBankListWidget: TopBankListWidgetVue,
    BankSearchAutoComplete: BankSearchAutoCompleteVue,
    LeadBlockCard: LeadBlockCardVue,
    HorizontalLine: HorizontalLineWidgetVue,
    BrokeragePlan: BrokeragePlanVue,
    JointEsign: JointEsignVue,
    JointAccountHolder: JointAccountHolderVue,
    CkycConfirmation: CkycConfirmationVue,
    DeliveryCharge: DeliveryChargeVue,
    OldAndNewPlan: OldAndNewPlanVue,
    DeliveryChargeJointAccount: DeliveryChargeJointAccountVue,
    OldAndNewPlanJointAccount: OldAndNewPlanJointAccountVue,
  },
  setup(props) {
    const layoutData = ref(props.layout);
    const shouldDisableStateKey = ref(props.isTextFiledDisabled);
    const screenId = ref(props.id);
    const valid = ref(false);
    const formRef = ref();
    const { handleAction } = ActionHandler;
    const debHandleAction = _.debounce(handleAction, 300);
    let isflexColumn = ref(layoutData.value);
    const { handleButtonDisabledState } = useButtonDisabledHooks();
    function haveAnyClass(cssClass) {
      return cssClass ?? "";
    }
    onUpdated(() => {
      handleButtonDisabledState(layoutData.value);
    });
    onMounted(() => {
      handleButtonDisabledState(layoutData.value);
    });

    return {
      layoutData,
      handleAction,
      screenId,
      formRef,
      useRuleParser,
      debHandleAction,
      isflexColumn,
      valid,
      shouldDisableStateKey,
      haveAnyClass,
    };
  },
};
</script>

<style scoped>
.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.widget {
  display: flex;
  flex: 1;
  border: 1px solid white;
}
</style>
