<template>
  <!-- && camsLoader" -->
  <v-app v-if="digiLockerLoader && isLoaded && esignLoader">
    <v-main>
      <v-row class="ma-0 h-100">
        <!-- <ButtonWidget
          class="force-back"
          :params="goBackParams"
          @click="handleForceBack"
          v-if="showForcebackBtn"
        /> -->
        <v-col
          md="5"
          :class="[
            isFullPageView ? 'd-none' : 'd-md-block border-right d-none',
          ]"
        >
          <div class="guidelines-panel m-auto">
            <template v-for="(guide, i) in guideline" :key="i">
              <ScreenView
                :id="getGuidelineId(i)"
                :screen="guide"
                v-if="checkRoute(guide.route)"
              />
            </template>
          </div>
        </v-col>

        <div
          class="d-md-none bottom-sheet-activator"
          @click.stop="drawer = !drawer"
          v-if="!checkTermsAndCond"
        >
          <v-icon size="18" class="mr-1"> mdi-information-outline </v-icon>
          <span v-if="!checkBrokerageRoute">NEED HELP?</span>
          <span v-else>Brokerage Details</span>
        </div>
        <div class="chat-bot-activator" v-if="isChatBotInitiated"></div>
        <v-bottom-sheet
          class="d-block d-md-none bottom-sheet"
          v-model="drawer"
          :class="[{ 'display-sheet': drawer }]"
        >
          <v-overlay v-model="drawer"></v-overlay>

          <template v-for="(guide, i) in guideline" :key="i">
            <ScreenView
              :id="getGuidelineId(i)"
              :screen="guide"
              v-if="checkRoute(guide.route)"
            />
          </template>
        </v-bottom-sheet>

        <v-col
          sm="12"
          :class="isFullPageView ? 'v-col-md-12' : 'v-col-md-7'"
          class="px-sm-9 px-xs-3 pb-0 px-sm-0"
        >
          <StyleContainer
            :style="imageStyle"
            v-if="!isStepperVisible && !isFullPageView && !isRegisterEmailPage"
          >
            <Image :params="imageParams" />
            <Image :params="oneCrImageParams" />
          </StyleContainer>

          <StyleContainer :style="imageStyle" v-if="isStepperVisible">
            <Image id="logoMob" :params="imageParams" />
            <Image id="logoMob" :params="oneCrImageParams" />
          </StyleContainer>

          <StyleContainer :style="stepperStyle" v-if="isStepperVisible">
            <Stepper id="stepper" :params="stepperParams" />
          </StyleContainer>

          <div
            :class="!isStepperVisible && !isFullPageView ? 'center-div' : ''"
          >
            <router-view :key="$route.params"></router-view>
          </div>
        </v-col>
      </v-row>

      <PopupWidget
        v-if="getActivePopups()"
        :id="getActivePopups()"
        :screen="getPopupScreen()"
        :params="{
          ...getPopupParams(),
          persistent: popupPersistent,
        }"
        @onClose="popupOnClose"
        :visible="showPopup()"
      />
    </v-main>
  </v-app>

  <!-- <v-app v-else-if="!camsLoader">
    <div class="center-screen">
      <h1>We are fetching the document</h1>
      <p class="pt-3 text-secondary">It shouldn't take more than 5 seconds</p>
      <p class="pt-3 text-secondary">Loading...</p>
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </v-app> -->
  <v-app v-else>
    <div class="center-screen">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <p class="pt-3">Loading...</p>
    </div>
  </v-app>
</template>

<script>
import ScreenView from "@/components/ScreenView.vue";
import StyleContainer from "@/components/StyleContainer.vue";
import StepperWidgetVue from "@/widgets/StepperWidget.vue";
import useMobileTabletDetector from "@/composables/useMobileDetector";
import { computed, defineAsyncComponent, ref, watch, watchEffect } from "vue";
const ImageWidgetVue = defineAsyncComponent(() =>
  import("@/widgets/ImageWidget.vue")
);

import useCams from "@/composables/useCams";
import useCreateRoutes from "@/composables/useCreateRoutes";
import useDigilocker from "@/composables/useDigilocker";
import useDigio from "@/composables/useDigio";
import useEsign from "@/composables/useEsign";
import useGetInitialRoute from "@/composables/useGetInitialRoute";
import useNomineeEsign from "@/composables/useNomineeEsign";
import { useRoute, useRouter } from "vue-router";

import { useStore } from "vuex";
import usePopupManager from "./composables/usePopupManager";
import useStartApp from "./composables/useStartApp";
// const PopupWidgetVue = defineAsyncComponent(() =>
//   import("./widgets/PopupWidget.vue"),
// );
import PopupWidgetVue from "@/widgets/PopupWidget.vue";
import appJson from "../layoutLogin.json";

// import ButtonWidget from "@/widgets/ButtonWidget.vue";

import { clickStream } from "./composables/clickStreamAnalytics";
import LocalNavigator from "./composables/useNavigation";
import ActionHandler, { clearUploadKeys } from "./handlers/action";
import { IN_SAME_SESSION } from "./constants";
import {
  deleteCookieWithDomain,
  getCookieValue,
  setCookie,
} from "./services/HttpClient";
//let clickStream = new ClickStreamAnalytics();
// import useMilestone from "./composables/useMilestone";

export default {
  name: "App",
  components: {
    ScreenView,
    StyleContainer,
    Image: ImageWidgetVue,
    Stepper: StepperWidgetVue,
    PopupWidget: PopupWidgetVue,
    // ButtonWidget,
  },
  setup() {
    let { getLayout, assignReferralCode, getIPAddress } = useStartApp();
    const {
      initPopups,
      getPopupScreen,
      getPopupParams,
      popupOnClose,
      showPopup,
      getActivePopups,
      closePopup,
      // openPopup,
    } = usePopupManager();
    let stepList = ref();
    let allStepList = ref();
    let isChatBotInitiated = ref(false);

    let isMobile = useMobileTabletDetector();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const path = computed(() => route.path);
    const state = store.state;
    // const layoutConfig = state.layout.config;
    const layoutConfig = computed({
      get() {
        return state.layout.config;
      },
    });

    const { onFinishDigilocker } = useDigilocker();
    const { onFinishEsign } = useEsign();

    const { onFinishDigio } = useDigio();
    const { onFinishCams } = useCams();
    const { onFinishNomineeEsign } = useNomineeEsign();
    let stepsArray;
    let currVal = ref("");

    (async () => {
      try {
        const host = window.location.host;
        const splittedHost = host.replace("www", "");
        deleteCookieWithDomain("ABNonTradeToken", splittedHost);
        deleteCookieWithDomain(
          import.meta.env.VITE_NEW_GUEST_TOKEN_KEY,
          splittedHost
        );
        deleteCookieWithDomain("ABNonTradeRefreshToken", splittedHost);
        deleteCookieWithDomain("ce_uid", splittedHost);
        deleteCookieWithDomain("requestId", splittedHost);

        const currentUrl = window.location;
        if (currentUrl.toString().includes(import.meta.env.VITE_OLD_KYC_URL)) {
          window.location.href = currentUrl
            .toString()
            .replace(
              import.meta.env.VITE_OLD_KYC_URL,
              import.meta.env.VITE_CURRENT_KYC_URL
            );
        }
        // const token = getCookieValue(import.meta.env.VITE_APP_GUEST_COOKIE);

        const abUserCookie = getCookieValue(
          import.meta.env.VITE_APP_USER_COOKIE
        );
        const abUserCookieData = ActionHandler.fnDecompressData(
          abUserCookie,
          ""
        );
        console.log("abUserCookieData ", abUserCookieData);
        console.log(
          " abUserCookieData?.NTAccessToken ",
          abUserCookieData?.NTAccessToken
        );
        const token =
          abUserCookieData?.NTAccessToken ||
          getCookieValue(import.meta.env.VITE_APP_GUEST_COOKIE);
        if (token) {
          setCookie(import.meta.env.VITE_APP_GUEST_COOKIE, token, 7, false);
        }

        if (
          token &&
          !(
            currentUrl.toString().includes("login") ||
            currentUrl.toString().includes("termsAndConditionPg") ||
            currentUrl.toString().includes("creditBureauTermsAndConditionPg") ||
            currentUrl.toString().includes("jointAccountLogin")
          )
        ) {
          await getLayout();
        } else if (
          (!token ||
            currentUrl.toString().includes("jointAccountLogin") ||
            currentUrl.toString().includes("login")) &&
          !(
            currentUrl.toString().includes("termsAndConditionPg") ||
            currentUrl.toString().includes("creditBureauTermsAndConditionPg")
          )
        ) {
          store.dispatch("setLayout", appJson);
          assignReferralCode();
          getIPAddress();
        } else if (currentUrl.toString().includes("termsAndConditionPg")) {
          store.dispatch("setLayout", appJson);
        } else if (
          currentUrl.toString().includes("creditBureauTermsAndConditionPg")
        ) {
          store.dispatch("setLayout", appJson);
        }

        // if (state.formData && state.formData.appNumber && window?.clarity) {
        //   // window.clarity()
        //   window
        //     .clarity(
        //       "identify",
        //       state.formData.appNumber,
        //       "kyc-web",
        //       "https://uatcdn.angelone.in/register/",
        //       state.formData.appNumber
        //     )
        //     .then((res) => {
        //       console.log("res", res);
        //     });
        // }
      } catch (error) {
        // console.log("error while fetching start api data");
      }

      // Set initial Route
      const { routes, steps, navStack, allSteps } = useCreateRoutes();

      const { initialRoute } = useGetInitialRoute();
      initPopups();
      stepList.value = steps;
      allStepList.value = allSteps;
      // Generate Routes
      routes.forEach((route) => {
        router.addRoute(route);
      });
      const chatBotDisplayRoutes = [
        "/panDetails",
        "/pan",
        "/panManual",
        "/panOCRConfirmation",
        "/bankChequeOCRConfirmation",
        "/bank",
        "/bankRPD",
        "/selfie",
      ];
      watchEffect(() => {
        if (isMobile.value) {
          ActionHandler.handleSingleAction({ type: "closeChatBot" });
        } else if (!["login", "jointAccountLogin"].includes(initialRoute)) {
          if (chatBotDisplayRoutes.includes(currVal.value)) {
            ActionHandler.handleSingleAction({ type: "openChatBot" });
          } else {
            ActionHandler.handleSingleAction({ type: "closeChatBot" });
          }
        }
      });
      // Create Navigation Stack
      navStack.forEach(async (route, idx) => {
        const routes = route.path.includes("/") ? route.path : "/" + route.path;
        history.pushState({}, "", `#${routes}`);
        if (navStack.length - 1 != idx) {
          LocalNavigator.pushState(route.path);
        }
      });
      // once navStack is created from state lets add current Route
      // const nominees = toRaw(state.formData.nominees) || [];
      // if (nominees.length) {
      //   history.pushState({}, "", `#/nominees`);
      //   LocalNavigator.pushState("/nominees");
      // }
      LocalNavigator.push(`/${initialRoute}`);
      history.pushState({}, "", `#${initialRoute}`);
    })();

    router.beforeEach((to, from, next) => {
      // const token = getCookieValue(import.meta.env.VITE_APP_GUEST_COOKIE);
      // if (token && checkToken(token)) {
      const inSameSession = sessionStorage.getItem(IN_SAME_SESSION) === "true";
      if (
        !inSameSession &&
        !to.fullPath.includes("thanks") &&
        !to.fullPath.includes("login")
      ) {
        sessionStorage.setItem(IN_SAME_SESSION, true);
      }
      onFinishDigilocker(route, to);
      onFinishDigio(route, to);
      onFinishEsign(route, to);
      onFinishCams(route, to);
      onFinishNomineeEsign(route, to);
      // if (useMilestone(to.fullPath)) {
      return next();
      // } else {
      // openPopup("backConfirmationCloseWindow");
      // }
      // } else {
      //   clearAllCookie();
      //   return "login";
      //   // return (window.location.href = window.location.origin);
      // }
    });

    // Set Router route listeners
    router.afterEach(async (to, from) => {
      clearUploadKeys();
      store.dispatch("setRoute", { to, from });
      const modalOpened = state.popups;
      for (const [key] of Object.entries(modalOpened)) {
        if (modalOpened[key]) {
          closePopup(key);
          break;
        }
      }
    });
    // const guideline = ref(state.layout.guidelines);
    const guideline = computed({
      get() {
        return state.layout.guidelines;
      },
    });

    const imageParams = {
      isImage: true,
      imageWidth: "169px",
      imageHeight: "32px",
      imageName: "logo.svg",
    };
    const oneCrImageParams = {
      isImage: true,
      imageWidth: "130px",
      imageHeight: "32px",
      imageName: "twoCrorelogo.svg",
      imageRightAligned: true,
    };

    const imageStyle = {
      margin: {
        top: 28,
        bottom: 20,
        left: 16,
        right: 16,
      },
    };

    const stepperStyle = {
      margin: {
        top: 28,
        bottom: 48,
        left: 20,
        right: 20,
      },
    };
    let drawer = ref(false);
    let isLoaded = ref(false);

    let digiLockerLoader = computed({
      get() {
        return store.state.isDigilockerLoaded;
      },
    });

    let camsLoader = computed({
      get() {
        return store.state.isCamsLoaded;
      },
    });

    let esignLoader = computed({
      get() {
        return store.state.isEsignLoaded;
      },
    });

    const popupPersistent = computed(() => {
      const activePopup = getActivePopups();
      return layoutConfig.value?.lockedPopups?.includes(activePopup);
    });

    watch(store.state, (currentValue) => {
      currVal.value = currentValue.activeRoute;
      isLoaded.value = currentValue?.layout?.screens ? true : false;
    });

    watch(
      () => store.state.formData.mobile,
      (currentPhoneNumber) => {
        const oldPhoneNumber = localStorage.getItem("phoneNumber");
        if (currentPhoneNumber !== oldPhoneNumber) {
          oldPhoneNumber != null && clickStream.triggerClickStreamEvents();
          localStorage.setItem("phoneNumber", currentPhoneNumber);
        }
      }
    );

    function checkRoute(guide = []) {
      return guide.find((el) => el === currVal.value);
    }

    const checkBrokerageRoute = computed(() => {
      return path?.value.includes("occupation");
    });

    const checkTermsAndCond = computed(() => {
      return path?.value.includes("termsAndConditionPg");
    });

    const isStepperVisible = computed(() => {
      return !layoutConfig.value?.stepperPreview?.paths.includes(path?.value);
    });

    const isFullPageView = computed(() => {
      return layoutConfig.value?.fullWidthPage?.paths.includes(path?.value);
    });
    const isRegisterEmailPage = computed(() => {
      return layoutConfig.value?.registerEmail?.paths.includes(path?.value);
    });

    const getGuidelineId = (i) => {
      return `guideline_${i}`;
    };
    watch(
      () => store.state.formData["INITIATE_CHATBOT"],
      () => {
        isChatBotInitiated.value = store.state.formData["INITIATE_CHATBOT"];
      }
    );

    const goBackParams = {
      btnText: "Go Back",
      btnColor: "primary",
      btnSize: "large",
      btnVariant: "plain",
      hasIcon: true,
      customStyles: "text-transform: initial; font-weight: 600;",
      iconName: "mdi-arrow-left-thin",
    };

    // const handleForceBack = () => {
    //   LocalNavigator.pop();
    // };

    // const showForcebackBtn = computed(() => {
    //   //This is temprory fix
    //   return path.value != "/register" && path.value != "/thanks";
    // });

    return {
      guideline,
      drawer,
      imageParams,
      oneCrImageParams,
      stepperParams: { steps: stepList, allStep: allStepList },
      imageStyle,
      stepperStyle,
      currVal,
      checkBrokerageRoute,
      checkTermsAndCond,
      getPopupScreen,
      getPopupParams,
      popupOnClose,
      showPopup,
      getActivePopups,
      checkRoute,
      stepsArray,
      isLoaded,
      getGuidelineId,
      digiLockerLoader,
      camsLoader,
      esignLoader,
      isStepperVisible,
      isFullPageView,
      isRegisterEmailPage,
      goBackParams,
      layoutConfig,
      popupPersistent,
      isChatBotInitiated,
      // handleForceBack,
      // showForcebackBtn,
    };
  },
};
</script>

<style lang="scss">
#app {
  font-family: $font-base-reg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.border-right {
  border-right: 1px solid $border-color;
}

.center-div {
  max-width: 480px;
  margin: 0 auto;
}

.guidelines-panel {
  margin: 60px auto 0;
  max-width: 435px;
}

.bottom-sheet-activator {
  @media (min-width: 962px) {
    display: none !important;
  }

  display: inline-flex !important;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  padding: 10px;
  text-align: right;
  background: $white;
  z-index: 1;
  height: 40px;
  color: $primary;
}

.chat-bot-activator {
  @media (min-width: 962px) {
    display: none !important;
  }

  display: inline-flex !important;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: 0px;
  width: 100%;
  text-align: right;
  background: $white;
  z-index: 1;
  height: 60px;
  color: $primary;
}

.oneCroreMobile {
  @media (min-width: 962px) {
    display: none !important;
  }

  top: 0px;
  right: 0px;
  width: 100%;
  padding: 10px;
  text-align: right;
  display: inline-flex !important;
}

.bottom-sheet {
  height: 0;
  transition: max-height ease-in 100ms;
  transform-origin: center center;
  position: fixed;
  bottom: 0;
  overflow: auto;
  z-index: 2002;
  background: $white;
  border-radius: $border-radius $border-radius 0 0;
  width: 100%;
  left: 0;
}

.display-sheet {
  height: auto;
  max-height: 75vh;
  transition: height ease-in 1000ms;
}

.center-screen {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.px-sm-0 {
  @media (max-width: 600px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.force-back {
  position: absolute !important;
  z-index: 10;
  left: 0;
  width: auto;
  margin: 0;
  text-align: left;

  &.btn-min-width {
    min-width: 0;
  }
}

// .v-application {
//   z-index: unset !important;
// }
</style>
