<template>
  <div class="popupContainer">
    <p
      style="
        text-align: left;
        font-size: 14px;
        background-color: #f4f6fb;
        padding: 4px 8px 4px 8px;
      "
    >
      We have revised our brokerage plans and pricing. Please review the details
      before completing the account opening process.
    </p>
    <div class="tableContainer">
      <table>
        <tr>
          <th></th>
        </tr>
        <tr class="tableRow">
          <td class="tableColumn">Details</td>
          <td class="tableColumn text-grey">{{ respFromAPI[0].planName }}</td>
        </tr>
        <template v-for="(item, idx) in rowsName" :key="idx">
          <tr class="tableRow">
            <td class="tableColumn">
              {{ item }}
            </td>
            <td class="tableColumn text-grey">
              {{ respFromAPI[0].details?.[item] }}
            </td>
          </tr>
        </template>
      </table>
    </div>
  </div>
  <p class="consentPara">
    By proceeding, you agree that you have read and verified the updated
    brokerage plan.
  </p>
  <div style="margin: auto; width: max-content; padding: 8px">
    <FormButton
      v-if="visibleWhen"
      :params="formButtonParams"
      id="proceedToEsignFormButton"
    />
  </div>
</template>

<script setup>
import { ref } from "vue";
import FormButton from "./FormButton.vue";
import useRuleParser from "@/composables/useRuleParser";

const props = defineProps({
  id: String,
  respFromAPI: Object,
  rowsName: Array,
});

const respFromAPI = ref(props.respFromAPI);
const rowsName = ref(props.rowsName);
const visibleWhen = useRuleParser(
  "journey-type:getCookieVal|=|jointAccount&&formData.jointAccountAppStatus:string|!=|approved&&formData.isJointAccountSubmitted:string|!=|Y&&features.newDeliveryCharges:boolean|=|true|false"
);

const formButtonParams = {
  btnText: "Submit Review",
  btnColor: "primary",
  btnSize: "large",
  customStyles: "margin:auto !important;",
  hasLoadingIndicator: true,
  hasFullMobWidth: true,
  onSubmit: [
    {
      type: "setFormData",
      bodyParameters: {
        submitJointAccountAction: true,
      },
    },
    {
      api: "/v1/kyc/personal",
      bodyParameters: {
        gender: "gender",
        maritalStatus: "maritalStatus",
        whatsAppNotification: "proceedESignCheck",
        settlementFrequency: "settlementFrequency",
        panFatherName: "panFatherName",
      },
      isPopup: true,
      popupId: "nominee",
      method: "POST",
      type: "navigateWithAPI",
      requestOn: "onChangeVal",
      post_route: "signature",
      apiValidationSuccess: "success",
      apiValidationEvent: {
        event_name: "SendPIdetailsapi",
        event_id: "73.0.0.27.5",
      },
      ctUser: {
        type: "updateUser",
        data: {
          Gender: "STATE:gender",
          Kyc_Client_Type: "STATE:clientType",
          Income: "STATE:income",
          isAadhaarMobileLinked: "STATE:isAadhaarMobileLinked",
          Marital_Status: "STATE:maritalStatus",
        },
      },
      ct: {
        event_name: "KYC_CTA_Clicked",
        bodyParameters: {
          event_category: "KYC2.0",
          eventAction: "Gender_Marital",
          event_type: "Personal API Click",
          AUC: "STATE:appNumber",
          gender: "STATE:gender",
          maritalStatus: "STATE:maritalStatus",
        },
      },
    },
    {
      api: "/v1/kyc/submit/jointAccount",
      method: "POST",
      type: "callAPI",
      widgetId: "jointAccountSubmitForReview",
      apiValidationSuccess: "success",
      triggerWhen:
        "formData.isDoBMatch:boolean|=|true&&formData.isNameMatch|=|true|<>||||<>|formData.displayEsignPDF:boolean|=|true&&formData.setuEsign:boolean|=|true",
    },
    {
      type: "setFormData",
      bodyParameters: {
        isJointAccountSubmitted: "Y",
      },
    },
    {
      type: "openPopupWithTimer",
      value: "jointAccountSubmittedThanks",
      timerinmilisecond: 1000,
      condition:
        "apiResponses.jointAccountSubmitForReview.status:string|=|success",
    },
  ],
};
</script>

<style scoped>
.popupContainer {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 3px;
  padding: 6px 10px 6px 10px;
  flex: 1;
  overflow-y: auto;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 14px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

.tableColumn {
  text-align: left;
  padding: 8px;
  vertical-align: top;
  font-weight: 600;
  color: #425061;
}

.tableRow {
  text-align: center;
}

.consentPara {
  text-align: center;
  color: slategrey;
  font-weight: bolder;
  font-size: 14px;
}

.text-grey {
  color: slategrey !important;
}
</style>
