<template>
  <v-card
    class="mx-auto leadBlockCard"
    color="#FFFF"
    min-height="220"
    variant="outline"
    :ripple="false"
  >
    <v-sheet color="#E8EBF1" :ripple="false">
      <v-card-item class="justify-center">
        <template v-slot:prepend>
          <v-card-title> Your Previous Application Details </v-card-title>
        </template>

        <v-divider vertical class="mx-2"></v-divider>
      </v-card-item>
    </v-sheet>
    <v-card :ripple="false" class="ma-2" rounded="lg" variant="flat">
      <v-card-item>
        <div class="py-1 leadeblockTextBOX">
          <div>
            <div class="mb-1 leade-text-header">Application Start Date</div>
            <div class="text-border">{{ startDate }}</div>
          </div>
          <div v-if="code">
            <div class="leade-text-header mb-1">Referral Code</div>
            <div class="text-border">{{ code }}</div>
          </div>
        </div>
      </v-card-item>

      <v-btn
        class="text-none mb-4 mt-2 mt-0"
        size="large"
        variant="flat"
        color="primary"
        @click="resumeThisApplication"
      >
        RESUME THIS APPLICATION
      </v-btn>
    </v-card>
  </v-card>
</template>

<script>
import CleaverTap from "@/composables/Clevertap";
import { clickStream } from "@/composables/clickStreamAnalytics";
import LocalNavigator from "@/composables/useNavigation";
import { clearUploadKeys } from "@/handlers/action";
import { reactive } from "vue";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "LeadBlockCard",
  props: {
    params: Object,
  },
  setup(props) {
    // let clickStream = new ClickStreamAnalytics();
    const { applicationStartDate, referalCode } = reactive(props.params);

    const startDate = computed({
      get() {
        return applicationStartDate
          ? store.state.formData[applicationStartDate]
          : "";
      },
    });
    const code = computed({
      get() {
        return referalCode ? store.state.formData[referalCode] : "";
      },
    });
    const goToLastPage = () => {
      clearUploadKeys();
      LocalNavigator.pop();
    };

    const resumeThisApplication = () => {
      const currentHash = window.location.hash.replace("#", "");
      const networkType = navigator?.connection?.effectiveType || "";
      const csEvents = {
        event_screen: "resumeapplication",
        event_type: "click",
        event_sub_type: "button",
        event_name: "s-leadblockpage",
        event_id: "73.0.0.2.19",
        event_metadata: `Screen-name: ${currentHash}, network-type: ${networkType} ,  user_agent:${window.navigator.userAgent}`,
      };
      // const clickStream = new ClickStreamAnalytics();
      clickStream.addEventList(csEvents, true);
      CleaverTap.addEvents("KYC_CTA_Clicked", {
        event_type: "resume this application",
        event_category: "KYC2.0",
        event_action: "leadblock",
        AUC: store.state.formData.appNumber,
      });

      let nvS = [...store.state.navStack];
      if (nvS[nvS.length - 1] == "/blockLead") {
        // nvS.pop();
        // store.dispatch("setPersistNavStack", [...nvS]);

        clearUploadKeys();

        if (nvS.length === 1) {
          window.location.href = import.meta.env.VITE_REGISTER_URL;
        } else {
          LocalNavigator.pop();
        }
      }
    };
    const store = useStore();

    return { startDate, code, resumeThisApplication, goToLastPage };
  },
};
</script>

<style scoped lang="scss">
.leadeblockTextBOX {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
  width: 100%;
  margin-bottom: 10px;

  > div {
    width: 50%;
  }
}

.leadBlockCard {
  height: 240px;

  @media (min-width: $device-width-md) {
    width: 500px;
  }

  width: 100%;
  border: 1px solid rgba(#e0e0ec, 0.6);
}

.text-border {
  padding: 5px;
  width: 100%;
  border: 1px solid rgba(#e8ebf1, 1);
}

.leade-text-header {
  text-align: left;
}
</style>
